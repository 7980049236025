var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs }
      }),
      _c(
        "v-card",
        {
          staticClass: "fill-height",
          attrs: { outlined: "", flat: "", width: "100%" }
        },
        [_c("company-form", { attrs: { isEditMode: true } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }